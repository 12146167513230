export const TESTIMONIALS = [
  {
    id: '89',
    title: '10 out of 10 with RoutePerfect',
    description:
      "My experience with RoutePerfect was fantastic! I really appreciate your service's help in planning my Europe trip! The planning process was seamless Everything was just fantastic with RoutePerfect, so again I thank you. I was very satisfied - 10 out of 10 with RoutePerfect. The package price was reasonable. In fact, I have already told several friends who are planning upcoming Europe trips about it, and they will be using it!",
    user: 'Vivek Kalia',
    picture: '/assets/images/testimonials/Vivek%20Kalia.png',
    location: '',
    type: 'site',
    time: '2017-10-02 06:56:06',
    status: 'active',
    rank: '67',
    map: '/images/testimonials/Vivek Kalia.jpeg',
  },
  {
    id: '88',
    title: 'EXTRA easy to use',
    description:
      'I have already recommended to several of my peers! I am really impressed. Saludos! Thanks for making my trip something amazing. The platform is EXTRA easy to use, and very intuitive. Being as graphic with the actual route in google map format, photographs of each location, etc makes it much easier than other travel management tools.',
    user: 'Arturo Hernandez',
    picture: '/images/testimonials/Arturo%20Hernandez%20100.png',
    location: '',
    type: 'site',
    time: '2017-10-02 06:52:21',
    status: 'active',
    rank: '66',
    map: '/images/testimonials/Arturo Hernandez.jpg',
  },
  {
    id: '87',
    title: 'This service is unmatched',
    description:
      'This service is unmatched. We found that the reviews and ratings were extremely accurate, and the site made things super-easy during the trip planning process. HIGHLY recommended!',
    user: 'Douglas Gavetti',
    picture: '/images/testimonials/Douglas%20Gavetti100.png',
    location: '',
    type: 'site',
    time: '2017-10-02 06:39:11',
    status: 'active',
    rank: '65',
    map: '/images/testimonials/Douglas Gavetti.jpg',
  },
  {
    id: '86',
    title: 'Very easy to use',
    description:
      'I was very satisfied and found it very easy to use. The hotels were amazing (especially in Sorrento). I would recommend your site to anyone! Thank you!',
    user: 'Michelle Tatom',
    picture: '/images/testimonials/michelle%20tatom100.png',
    location: '',
    type: 'site',
    time: '2017-08-07 11:06:43',
    status: 'active',
    rank: '64',
    map: '/images/testimonials/michelle tatom.jpg',
  },
  {
    id: '85',
    title: 'Thanks for making our trip easier!',
    description:
      'It was fantastic we will definitely recommend Routeperfect to our family & friends, thanks for making our trip easier!',
    user: 'Suzie Novelli',
    picture: '/images/testimonials/suzie%20novelli100.png',
    location: '',
    type: 'site',
    time: '2017-08-02 12:15:22',
    status: 'active',
    rank: '63',
    map: '/images/testimonials/suzie novelli.jpg',
  },
  {
    id: '84',
    title: 'So easy to plan',
    description:
      "Thank you for your email, our trip was amazing. I found the whole experience of using RoutePerfect brilliant. It made it so easy to plan your trip, and work out travelling time between each place, so we weren't in the car over 2 hours each journey. The hotels we chose were great. And the reviews were spot on. We loved our whole trip from start to finish and we have already discussed where we would like to go next year. So I will definitely be using RoutePerfect again.",
    user: 'Denise Hales',
    picture: '/images/testimonials/denise%20hales100.png',
    location: '',
    type: 'site',
    time: '2017-08-02 12:10:57',
    status: 'active',
    rank: '62',
    map: '/images/testimonials/denise hales.jpg',
  },
  {
    id: '79',
    title: 'Very helpful!',
    description:
      'I planned a trip for a small trio of girlfriends. We were very pleased with our trip to Europe, and your site is what made it such a smooth and easy process for me to select our hotels. Our hotels were all decent and all went well. We will be using your site again in the future as it was very helpful!',
    user: 'Jenny Okarma',
    picture: '/images/testimonials/Jenny%20Okarma100.png',
    location: '',
    type: 'site',
    time: '2017-07-06 13:00:07',
    status: 'active',
    rank: '58',
    map: '/images/testimonials/Jenny Okarma.JPG',
  },
  {
    id: '78',
    title: "I can't say enough good things about this experience!",
    description:
      "I came across your site and immediately found it extremely user-friendly and easy to navigate. All of the available options, the layout, the mapping ability, and the hotel selection simulation and pricing calculations were honestly as easy as the click of the button. The live and direct links to TripAdvisor were extremely helpful! I have to say, despite reading the testimonials, I was really nervous about using it, but eventually your site proved to just have better pricing overall and easier mode of selection. And I realized everything worked SO SMOOTHLY! Every check-in experience was ultimately seamless. It left more time for enjoying our travel :) I honestly believe that using RoutePerfect was one of the best online customer experiences I have ever had, if not THE BEST. Your staff was extremely responsive, and made me feel truly at ease by being friendly, courteous and reassuring. RoutePerfect is honestly THE BEST TRIP PLANNING tool I have ever used. I have already recommended RoutePerfect to my group of friends. And I honestly can't wait to use your site for booking my next European adventure.",
    user: 'Katherine Canario',
    picture: '/images/testimonials/Katherine%20Canario100.PNG',
    location: '',
    type: 'site',
    time: '2017-07-05 13:45:34',
    status: 'active',
    rank: '57',
    map: '/images/testimonials/Katherine Canario.JPG',
  },
  {
    id: '77',
    title: 'Will recommend RoutePerfect to anyone planning a trip',
    description:
      'I was more than pleased with my trip! The hotels that RoutePerfect suggested were fantastic and helped keep up comfortable and on budget. When our flight to Madrid was delayed, RoutePerfect\u2019s customer service called the hotel (as there was a language barrier) to ensure we were able to check in. I will recommend RoutePerfect to anyone planning a trip, and will use your services in the future.',
    user: 'Teri Silvey',
    picture: '/images/testimonials/Teri%20Silvey100.PNG',
    location: '',
    type: 'site',
    time: '2017-07-05 13:28:48',
    status: 'active',
    rank: '56',
    map: '/images/testimonials/Teri Silvey.jpg',
  },
  {
    id: '76',
    title: 'The trip was great!',
    description:
      'I really enjoyed using RoutePerfect and will definitely be using it more in the future. I used to use another website, but not any more. Your site has much better hotels. All check-ins were smooth, and hotels were as advertised. The trip was great!',
    user: 'Patricia Mata',
    picture: '/images/testimonials/Patricia%20Mata100.PNG',
    location: '',
    type: 'site',
    time: '2017-07-05 13:26:48',
    status: 'active',
    rank: '55',
    map: '/images/testimonials/Patricia Mata.jpg',
  },
];

import { Component, Input, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { Config } from 'src/app/core/configs/config';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { TESTIMONIALS } from '../../constants/testimonials.const';

import { SwiperDirective } from '../../directives/swiper.directive';

import { BreakpointService } from 'src/app/core/services/breakpoint.service';
import { DomainTrackingService } from 'src/app/core/services/domain-tracking.service';

import { TestimonialCardComponent } from '../testimonial-card/testimonial-card.component';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
  imports: [
    RouterLink,
    TranslateModule,
    SwiperDirective,
    TestimonialCardComponent,
  ],
})
export class TestimonialsComponent {
  @Input() title: string = '';
  public readonly cdnUrl = Config.cdnUrl.substring(0, Config.cdnUrl.length - 1);
  public testimonials = TESTIMONIALS;

  swiperOptions: SwiperOptions = {
    lazyPreloadPrevNext: 4,
    spaceBetween: 16,
    autoplay: {
      delay: 8_000,
      disableOnInteraction: false,
    },
    speed: 1500,
    breakpoints: {
      375: {
        slidesPerView: 1.35,
        spaceBetween: 16,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 16,
      },
      524: {
        slidesPerView: 1.4,
        spaceBetween: 20,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 20,
      },
      768: {
        slidesPerView: 1.5,
        spaceBetween: 24,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
      },
      1100: {
        slidesPerView: 2,
        spaceBetween: 32,
        slidesOffsetBefore: 32,
        slidesOffsetAfter: 32,
      },
    },
  };

  @ViewChild(SwiperDirective) swiper!: SwiperDirective;

  constructor(
    public readonly domainTrackingService: DomainTrackingService,
    protected readonly breakpointService: BreakpointService,
  ) {}
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { GetNamePipe } from '../../pipes/get-name.pipe';
import { TruncatePipe } from '../../pipes/truncate.pipe';

import { CloudinaryService } from 'src/app/core/services/cloudinary.service';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';
import { TestimonialsService } from 'src/app/core/services/testimonials.service';

@Component({
  selector: 'app-testimonial-card',
  templateUrl: './testimonial-card.component.html',
  styleUrls: ['./testimonial-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [GetNamePipe, TruncatePipe],
})
export class TestimonialCardComponent implements OnInit {
  @Input() showMapImage: boolean = false;
  @Input() disableTruncate: boolean = false;
  @Input() testimonial: any;
  @Input() cdnUrl: string;

  constructor(
    private router: Router,
    private cloudinaryService: CloudinaryService,
    private testimonialsService: TestimonialsService,
    protected readonly breakpointService: BreakpointService,
  ) {}

  ngOnInit(): void {
    if (this.testimonial && !this.testimonial.picture.includes('cloudinary')) {
      const imgUrl = this.cdnUrl + this.testimonial.picture;
      this.testimonial.picture = this.cloudinaryService.getCloudImage(imgUrl);
    }
  }

  async redirectToTestimonials(testimonial: any): Promise<void> {
    this.testimonialsService.setFirst(testimonial.id);
    await this.router.navigate(['/trip-testimonials']);
  }
}

<div class="testimonial"
     title="{{ testimonial.user | getName }}'s testimonial"
     (click)="redirectToTestimonials(testimonial)">

    <div class="testimonial__image--wrapper">
        <img class="testimonial__image"
             [src]="testimonial.picture"
             width="86"
             height="86"
             loading="lazy"
             alt="{{ testimonial.user | getName }}'s testimonial">
    </div>

    <div class="testimonial__separator"></div>

    <div class="testimonial__content">
        <div class="testimonial__content__user">
            {{ testimonial.user }}
        </div>
        <div class="testimonial__content__title">
            {{ testimonial.title }}
        </div>
        <div class="testimonial__content__description">
            {{ disableTruncate ? testimonial.description : (testimonial.description | truncate: 80) }}
        </div>

        <span class="read-more">Read more</span>
    </div>
</div>

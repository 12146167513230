<div class="testimonials container">
    <div class="testimonials-inner">
        <h3 class="testimonials-title">
            <a [routerLink]="['/trip-testimonials']" target="_blank">
                @if (title) {
                    {{ title | translate }}
                } @else {
                    {{ (breakpointService.isMobile() ? 'TESTIMONIALS.TESTIMONIALS' : 'TESTIMONIALS.TITLE') | translate }}
                }
            </a>
        </h3>

        <div class="testimonials-cards">
            <div class="testimonials__wrapper__cards" appSwiper [swiperOptions]="swiperOptions">
                <div class="swiper-wrapper" style="padding: 6px 16px">
                    @for (testimonial of testimonials; track testimonial.id) {
                        <div class="swiper-slide">
                            <app-testimonial-card
                                    [cdnUrl]="cdnUrl"
                                    [testimonial]="testimonial">
                            </app-testimonial-card>
                        </div>
                    }
                </div>
            </div>
        </div>

        @if (breakpointService.isMobile()) {
            <div class="testimonials__more-container">
                <a [routerLink]="['/trip-testimonials']"
                   class="testimonials__more">
                    {{ 'TESTIMONIALS.SEE_ALL_REVIEW' | translate }}
                </a>
            </div>
        }
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiSettingsService } from 'src/app/core/services/api-settings.service';

@Injectable({
  providedIn: 'root',
})
export class TestimonialsService {
  firstTestimonialId: number = null;

  constructor(
    public http: HttpClient,
    public apiSettingsService: ApiSettingsService,
  ) {}

  fetchRandomTestimonials(): Observable<any> {
    const queryString: string = this.firstTestimonialId
      ? `api/testimonials/random?testimonialId=${this.firstTestimonialId}`
      : 'api/testimonials/random';
    return this.http.get(this.apiSettingsService.apiUrl + queryString);
  }

  setFirst(id: number): void {
    this.firstTestimonialId = id;
  }
}
